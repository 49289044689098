import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MDBox from "components/MDBox";
import breakpoints from "assets/theme/base/breakpoints";
import EnsightSettings from "./components/ensightSettings";
import ParkPliantSettings from "./components/parkpliantSettings";
import SpotHeroSettings from "./components/spotHeroSettings";
import T2Settings from "./components/t2Settings";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <MDBox sx={{ p: 3 }}>{children}</MDBox>}
    </div>
  );
}

function Overview() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("subTab");
  const userData = useSelector((state) => state.users?.meInfo);
  const roleModules = userData?.roleModules || {};
  const [tabValue, setTabValue] = useState(
    activeTab ? activeTab : roleModules.Setting_view ? "ensightSettings" : ""
  );

  console.log('roles',roleModules)

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    queryParams.set("subTab", newValue);
    const updatedQueryString = queryParams.toString();
    navigate(`?${updatedQueryString}`);
  };
  return (
    <MDBox mt={5} mb={3}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto" }}>
          <AppBar position="static">
            <Tabs
              orientation={tabsOrientation}
              value={tabValue}
              onChange={handleSetTabValue}
            >
              <Tab
                label="Ensight Settings"
                value="ensightSettings"
              />
              <Tab
                label="Parkpliant Settings"
                value="parkpliantSettings"
              />
              <Tab
                label="SpotHero Settings"
                value="spotHeroSettings"
              />
              <Tab
                label="T2 Settings"
                value="t2Settings"
              />
            </Tabs>
          </AppBar>

          <TabPanel value={tabValue} index={"ensightSettings"}>
            <EnsightSettings />
          </TabPanel>
          <TabPanel value={tabValue} index={"parkpliantSettings"}>
            <ParkPliantSettings />
          </TabPanel>
          <TabPanel value={tabValue} index={"spotHeroSettings"}>
            <SpotHeroSettings />
          </TabPanel>
          <TabPanel value={tabValue} index={"t2Settings"}>
            <T2Settings />
          </TabPanel>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Overview;
