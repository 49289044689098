import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import MessagesTable from "./components/MessagesTable";
import CreateChatBotMessage from "./components/CreateChatBotMessage";
import { getMessage, getCalendarDataForMessages } from "store/slice/message/messageSlice";
import { setPlaceIdForMessages } from "store/slice/message/messageSlice";
import MDDropDown from "components/MDDropDown";
import moment from "moment";
import { InputLabel, MenuItem, FormControl, IconButton } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListIcon from "@mui/icons-material/List";
import { capitalizeFirstLetter } from "global/functions";
import MessagesList from "./components/MessagesList";
import MessagesCalendarView from "./components/MessagesCalendarView";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PlacePicker from "components/MDPlacePicker/PlacePicker";
function Messages({ isSettings }) {
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [flowType, setFlowType] = useState("");
  const placesData = useSelector((state) => state.places?.selectedPlace);
  const { messages } = useSelector((state) => state.messages);
  const { calendarDataForMessages } = useSelector((state) => state.messages);
  const currentDay = moment().format("dddd").toLowerCase();
  console.log("currentDay =====>", currentDay);
  const [selectedDay, setSelectedDay] = useState(
    capitalizeFirstLetter(currentDay)
  );
  const [calendarView, setCalendarView] = useState(false);

  let placeId =
    placesData?._id || new URLSearchParams(location?.search).get("placeId");

  useEffect(() => {
    if (!placeId || !selectedDay) {
      return;
    }

    const payload = {
      placeId,
      selectedDay,
    };

    dispatch(getMessage(payload));
    dispatch(getCalendarDataForMessages(payload));
  }, [placeId, selectedDay]);

  const handleDayChange = (event) => {
    setSelectedDay(capitalizeFirstLetter(event.target.value));
  };
  const Wrapper = isSettings ? MDBox : DashboardLayout
  return (
    <Wrapper>
      {!isSettings && <DashboardNavbar />}
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={1}
                mt={-2}
                py={1}
                px={1}
                variant="gradient"
                bgColor={sidenavColor}
                borderRadius="lg"
                coloredShadow={sidenavColor}
                className="d-flex align-items-center gap-2"
              >
                <MDTypography
                  variant="h6"
                  color="white"
                  className="flex-grow-1"
                >
                  Messages
                </MDTypography>
                <MDButton
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setFlowType("BOTCOPY");
                    setDialogOpen(true);
                  }}
                >
                  Create ChatBot
                </MDButton>
                {/* <MDButton
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setFlowType("SMS");
                    setDialogOpen(true);
                  }}
                >
                  Create SMS
                </MDButton> */}
                <IconButton
                  onClick={() => setCalendarView(!calendarView)}
                  color="white"
                >
                  {calendarView ? <ListIcon /> : <CalendarMonthIcon />}
                </IconButton>
              </MDBox>
              <MDBox pb={3} pt={1}>
                {calendarView ? (
                  <MessagesCalendarView calenderData={calendarDataForMessages ?? []} />
                ) : (

                  <MDBox>
                    {!isSettings &&
                      <MDBox pl={2} pr={2} pt={1}>
                        <PlacePicker />
                      </MDBox>}
                    <MDBox pl={2} pr={2} pb={2} pt={3}>
                      <FormControl fullWidth>
                        <InputLabel id="day-select-label">
                          Select Day
                        </InputLabel>
                        <MDDropDown
                          labelId="day-select-label"
                          id="day-select"
                          label="Select Day"
                          value={selectedDay}
                          onChange={handleDayChange}
                        >
                          {[
                            "Sunday",
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                          ].map((day) => (
                            <MenuItem key={day} value={day}>
                              {day.charAt(0).toUpperCase() + day.slice(1)}
                            </MenuItem>
                          ))}
                        </MDDropDown>
                      </FormControl>
                    </MDBox>

                    <MessagesList
                      messages={messages}
                      selectedDay={selectedDay}
                      placeId={placeId}
                    />
                  </MDBox>
                )}
                <CreateChatBotMessage
                  dialogOpen={dialogOpen}
                  onClose={() => setDialogOpen(false)}
                  selectedDay={selectedDay}
                  // updateParentData={handleGetMessages}
                  flowType={flowType}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </Wrapper>
  );
}

export default Messages;
