import { Switch } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useRef, useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import { updatePlace } from "store/slice/places/placeSlice";
import MDSnackbar from "components/MDSnackbar";
import { getPlaceById } from "store/slice/places/placeSlice";
import { setSelectedPlace } from "store/slice/places/placeSlice";

// Define the validation schema
const validationSchema = Yup.object().shape({
  facailityId: Yup.string()
    .required("FacailityId is required!")
});

function SpotHeroSettings() {
  const [controller] = useMaterialUIController();

  const { sidenavColor } = controller;
  const formikRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const placesData = useSelector((state) => state.places?.selectedPlace);
  let placeId =
    placesData?._id || new URLSearchParams(location?.search).get("placeId");
  const [notification, setNotification] = useState({
    color: "",
    icon: "",
    title: "",
    content: "",
    show: false,
    close: () => setNotification({ ...notification, show: false }),
  });

  useEffect(() => {
    getPlace();
  }, [placesData?._id]);

  function getPlace() {
    if (placesData) {
      formikRef?.current?.setFieldValue(
        "enabled",
        placesData.spotHeroSettings?.enabled || false
      );
      formikRef?.current?.setFieldValue(
        "facilityId",
        placesData.spotHeroSettings?.facilityId || ""
      );
      return;
    }
  }

  const handleSpotHeroSettingsUpdate = (value) => {
    const type = "UPDATE";
    const spotHeroSettings = {
      enabled: value.enabled,
      facilityId: value.facilityId
    }

    dispatch(updatePlace({ placeId, type, spotHeroSettings }))
      .unwrap()
      .then((res) => {
        const success = res?.success;
        if (success) {
          dispatch(getPlaceById(placeId))
            .unwrap()
            .then((res) => {
              if (res?.success) {
                dispatch(setSelectedPlace(res?.place));
              }
            });
        }
        setNotification({
          ...notification,
          color: success ? "success" : "error",
          title: success ? "Success" : "Error",
          content: res?.message,
          icon: success ? "check" : "warning",
          show: true,
        });
      })
      .catch((err) => {
        console.error("Error creating:", err);
        setNotification({
          ...notification,
          color: "error",
          title: "Error",
          content: err?.message,
          icon: "warning",
          show: true,
        });
      });
  };

  return (
    <MDBox>
      <MDBox pt={2} pb={3}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            enabled: false,
            facilityId: ""
          }}
          validationSchema={formikRef?.current?.enabled ? validationSchema : null}
          onSubmit={(value, action) => {
            console.log("values", value);
            handleSpotHeroSettingsUpdate(value);
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid className="mb-3">
                    <MDTypography variant="subtitle2" style={{minWidth: "120px"}}>
                      Facility Id:
                    </MDTypography>
                    <MDInput
                      inputProps={{
                        type: "string",
                      }}
                      name={`facilityId`}
                      required={props.values.enabled}
                      value={props.values.facilityId}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={props.errors.facilityId && props.touched.facilityId}
                      success={
                        !props.errors.facilityId && props.touched.facilityId
                      }
                      helperText={
                        props.errors.facilityId &&
                        props.touched.facilityId &&
                        props.errors.facilityId
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <MDBox pt={2} display="flex" justifyContent="end" alignItems="center">
                    <MDTypography variant="subtitle2" style={{minWidth: "120px"}}>
                      Enabled:
                    </MDTypography>
                    <Switch
                      checked={props.values.enabled}
                      onChange={(e) => {
                        props.handleChange(e);

                        props.setFieldValue(
                          "enabled",
                          e.target.checked
                        );
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </MDBox>
                </Grid>

                <Grid xs={12}>
                  <MDBox pt={2} display="flex" justifyContent="end">
                    <MDButton
                      color={sidenavColor}
                      variant="contained"
                      type="submit"
                    >
                      Save
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </MDBox>
      <MDSnackbar
        color={notification.color}
        icon={notification.icon}
        title={notification.title}
        content={notification.content}
        open={notification.show}
        close={notification.close}
        bgWhite
      />
    </MDBox>
  );
}

export default SpotHeroSettings;
