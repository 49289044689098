import { Switch } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useRef, useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import { updatePlace } from "store/slice/places/placeSlice";
import MDSnackbar from "components/MDSnackbar";
import { getPlaceById } from "store/slice/places/placeSlice";
import { setSelectedPlace } from "store/slice/places/placeSlice";

// Define the validation schema
const validationSchema = Yup.object().shape({
  apiKey: Yup.string()
    .required("Api Key is required!"),
  apiPassword: Yup.string()
    .required("Api Password is required!")
});

function ParkPliantSettings() {
  const [controller] = useMaterialUIController();

  const { sidenavColor } = controller;
  const formikRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const placesData = useSelector((state) => state.places?.selectedPlace);
  let placeId =
    placesData?._id || new URLSearchParams(location?.search).get("placeId");
  const [notification, setNotification] = useState({
    color: "",
    icon: "",
    title: "",
    content: "",
    show: false,
    close: () => setNotification({ ...notification, show: false }),
  });

  useEffect(() => {
    getPlace();
  }, [placesData?._id]);

  function getPlace() {
    if (placesData) {
      formikRef?.current?.setFieldValue(
        "enabled",
        placesData.parkpliantSettings?.enabled || false
      );
      formikRef?.current?.setFieldValue(
        "apiKey",
        placesData.parkpliantSettings?.apiKey || ""
      );
      formikRef?.current?.setFieldValue(
        "apiPassword",
        placesData.parkpliantSettings?.apiPassword || ""
      );
      formikRef?.current?.setFieldValue(
        "slackEnabled",
        placesData.parkpliantSettings?.slackSettings?.enabled || false
      );
      formikRef?.current?.setFieldValue(
        "slackChannel",
        placesData.parkpliantSettings?.slackSettings?.slackChannel || ""
      );
      return;
    }
  }

  const handleParkPliantSettingsUpdate = (value) => {
    const type = "UPDATE";
    const parkpliantSettings = {
      enabled: value.enabled,
      apiKey: value.apiKey,
      apiPassword: value.apiPassword,
      slackSettings: {
        enabled: value.slackEnabled,
        slackChannel: value.slackChannel,
      }
    }

    dispatch(updatePlace({ placeId, type, parkpliantSettings }))
      .unwrap()
      .then((res) => {
        const success = res?.success;
        if (success) {
          dispatch(getPlaceById(placeId))
            .unwrap()
            .then((res) => {
              if (res?.success) {
                dispatch(setSelectedPlace(res?.place));
              }
            });
        }
        setNotification({
          ...notification,
          color: success ? "success" : "error",
          title: success ? "Success" : "Error",
          content: res?.message,
          icon: success ? "check" : "warning",
          show: true,
        });
      })
      .catch((err) => {
        console.error("Error creating:", err);
        setNotification({
          ...notification,
          color: "error",
          title: "Error",
          content: err?.message,
          icon: "warning",
          show: true,
        });
      });
  };

  return (
    <MDBox>
      <MDBox pt={2} pb={3}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            enabled: false,
            apiKey: "",
            apiPassword: "",
            slackEnabled: false,
            slackChannel: "",
          }}
          validationSchema={formikRef?.current?.enabled ? validationSchema : null}
          onSubmit={(value, action) => {
            console.log("values", value);
            handleParkPliantSettingsUpdate(value);
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid className="mb-3">
                    <MDTypography variant="subtitle2" style={{minWidth: "120px"}}>
                      Api Key:
                    </MDTypography>
                    <MDInput
                      inputProps={{
                        type: "string",
                      }}
                      name={`apiKey`}
                      required={props.values.enabled}
                      value={props.values.apiKey}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={props.errors.apiKey && props.touched.apiKey}
                      success={
                        !props.errors.apiKey && props.touched.apiKey
                      }
                      helperText={
                        props.errors.apiKey &&
                        props.touched.apiKey &&
                        props.errors.apiKey
                      }
                    />
                  </Grid>

                  <Grid className="mb-3">
                    <MDTypography variant="subtitle2" style={{minWidth: "120px"}}>
                      API Password:
                    </MDTypography>
                    <MDInput
                      inputProps={{
                        type: "string",
                      }}
                      name={`apiPassword`}
                      required={props.values.enabled}
                      value={props.values.apiPassword}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={props.errors.apiPassword && props.touched.apiPassword}
                      success={
                        !props.errors.apiPassword && props.touched.apiPassword
                      }
                      helperText={
                        props.errors.apiPassword &&
                        props.touched.apiPassword &&
                        props.errors.apiPassword
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <MDBox pt={2} display="flex" justifyContent="end" alignItems="center">
                    <MDTypography variant="subtitle2" style={{minWidth: "120px"}}>
                      Enabled:
                    </MDTypography>
                    <Switch
                      checked={props.values.enabled}
                      onChange={(e) => {
                        props.handleChange(e);

                        props.setFieldValue(
                          "enabled",
                          e.target.checked
                        );
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </MDBox>
                </Grid>

                <Grid item xs={6}>
                  <MDTypography variant="h5" style={{minWidth: "120px"}}>
                    Slack Notifications Settings:
                  </MDTypography>

                  <MDBox pt={2} display="flex" justifyContent="space-between" className="mt-0 m-3" alignItems="center">
                    <MDTypography variant="subtitle2" style={{minWidth: "120px"}}>
                      Enabled:
                    </MDTypography>
                    <Switch
                      checked={props.values.slackEnabled}
                      onChange={(e) => {
                        props.handleChange(e);

                        props.setFieldValue(
                          "slackEnabled",
                          e.target.checked
                        );
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </MDBox>

                  <Grid className="m-3">
                    <MDTypography variant="subtitle2" style={{minWidth: "120px"}}>
                      Slack Channel:
                    </MDTypography>
                    <MDInput
                      inputProps={{
                        type: "string",
                      }}
                      name={`slackChannel`}
                      required={props.values.slackEnabled}
                      value={props.values.slackChannel}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={props.errors.slackChannel && props.touched.slackChannel}
                      success={
                        !props.errors.slackChannel && props.touched.slackChannel
                      }
                      helperText={
                        props.errors.slackChannel &&
                        props.touched.slackChannel &&
                        props.errors.slackChannel
                      }
                    />
                  </Grid>
                </Grid>

                <Grid xs={12}>
                  <MDBox pt={2} display="flex" justifyContent="end">
                    <MDButton
                      color={sidenavColor}
                      variant="contained"
                      type="submit"
                    >
                      Save
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </MDBox>
      <MDSnackbar
        color={notification.color}
        icon={notification.icon}
        title={notification.title}
        content={notification.content}
        open={notification.show}
        close={notification.close}
        bgWhite
      />
    </MDBox>
  );
}

export default ParkPliantSettings;
