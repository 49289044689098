import "./style.css";
import {
  LinearProgress
} from "@mui/material";
import MDDataGrid from "components/MDDataGrid/MDDataGrid";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import EmptyTableRowOverlay from "components/MDEmptyOverlay/EmptyTableRowOverlay";
import { useEffect, useMemo, useState } from "react";
import CircularIndeterminate from "components/MDLoading";
import MDSnackbar from "components/MDSnackbar";
import { debounce, isEmpty } from "lodash";
import { formatDateWithTimezone } from "global/functions";
import { getEnforcementsByPlaceId } from "store/slice/enforcements/enforcementSlice";
import { useDispatch, useSelector } from "react-redux";

const EnforcementsTable = (props) => {
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const dispatch = useDispatch();
  useState(false);
  const { placeId, startDate, endDate, searchText, tz, activeTab } = props;
  const enforcementsList = useSelector((state) => state.enforcements?.list);
  const totalEnforcements = useSelector(
    (state) => state.enforcements?.totalEnforcements
  );
  const loading = useSelector((state) => state.enforcements?.loading);
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [licensePlates, setLicensePlates] = useState([]);
  const [notification, setNotification] = useState({
    color: "",
    icon: "",
    title: "",
    content: "",
    show: false,
    close: () => setNotification({ ...notification, show: false }),
  });

  const columnsData = [
    {
      field: "ID",
      headerName: " ID",
      width: 120,
      editable: false,
      renderCell: ({ row }) => (
        <MDTypography
          display="block"
          variant="caption"
          sx={{ color: "black.light" }}
        >
          {` ${
            row.referenceId
          }`}
        </MDTypography>
      ),
    },
    {
      field: "dataCreated",
      headerName: "Created Date",
      width: 250,
      editable: false,
      renderCell: ({ row }) => (
        <MDTypography
          display="block"
          variant="caption"
          sx={{ color: "black.light" }}
        >
          {` ${
            formatDateWithTimezone(
              row.dataCreated,
              row?.placeId?.timeZoneId
            ) || "-"
          }`}
        </MDTypography>
      ),
    },
    {
      field: "entryTime",
      headerName: "Entry Time",
      width: 250,
      editable: false,
      renderCell: ({ row }) => (
        <MDTypography
          display="block"
          variant="caption"
          sx={{ color: "black.light" }}
        >
          {` ${row.entry?.event_time || "-"}`}
        </MDTypography>
      ),
    },
    {
      field: "exitTime",
      headerName: "Exit Time",
      width: 250,
      editable: false,
      renderCell: ({ row }) => (
        <MDTypography
          display="block"
          variant="caption"
          sx={{ color: "black.light" }}
        >
          {` ${row.exit?.event_time || "-"}`}
        </MDTypography>
      ),
    },
    {
      field: "licensePlate",
      headerName: "License Plates",
      width: 170,
      editable: false,
      renderCell: ({ row }) => (
        <>
          <div>
            <MDBadge
              badgeContent={row.plate}
              color={sidenavColor}
              variant="gradient"
              size="sm"
              sx={{ marginRight: 1 }}
            />
          </div>
        </>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      editable: false,
      renderCell: ({ row }) => (
        <MDTypography
          display="block"
          variant="caption"
          sx={{ color: "black.light" }}
        >
          {` ${row.status || "-"}`}
        </MDTypography>
      ),
    }
  ];

  const getEnforcementsData = async (pageNo) => {
    const payload = {
      tz,
      pageNo: pageNo || 0,
      status: activeTab,
      placeId,
      search: searchText,
      startDate,
      endDate,
    };
    dispatch(getEnforcementsByPlaceId(payload));
  };

  const debounceFn = useMemo(
    () => debounce(getEnforcementsData, 1000),
    [getEnforcementsData]
  );

  useEffect(() => {
    debounceFn();
  }, [startDate, endDate, searchText, placeId]);

  const handlePageChange = (params) => {
    getEnforcementsData(params.page);
    setPage(params.page);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
  };

  return (
    <>
      <MDBox mt={2}>
        <MDDataGrid
          rows={!isEmpty(enforcementsList) ? enforcementsList : []}
          rowCount={totalEnforcements}
          columns={columnsData}
          getRowId={(row) => row._id}
          disableSelectionOnClick
          loading={loading}
          pagination
          paginationMode="server"
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(params) => {
            handlePageChange(params);
          }}
          onPageSizeChange={(params) => handlePageSizeChange(params)}
          components={{
            NoRowsOverlay: EmptyTableRowOverlay,
            LoadingOverlay: LinearProgress,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 1000 },
            },
          }}
          autoHeight
        />
      </MDBox>
      <CircularIndeterminate
        type="full"
        size={20}
        text="Please wait..."
        open={loading}
      />
      <MDSnackbar
        color={notification.color}
        icon={notification.icon}
        title={notification.title}
        content={notification.content}
        open={notification.show}
        close={notification.close}
        bgWhite
      />
    </>
  );
};

export default EnforcementsTable;
