import { Switch } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useRef, useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import { updatePlace } from "store/slice/places/placeSlice";
import MDSnackbar from "components/MDSnackbar";
import { getPlaceById } from "store/slice/places/placeSlice";
import { setSelectedPlace } from "store/slice/places/placeSlice";

function SpotHerongs() {
  const [controller] = useMaterialUIController();

  const { sidenavColor } = controller;
  const formikRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const placesData = useSelector((state) => state.places?.selectedPlace);
  let placeId =
    placesData?._id || new URLSearchParams(location?.search).get("placeId");
  const [notification, setNotification] = useState({
    color: "",
    icon: "",
    title: "",
    content: "",
    show: false,
    close: () => setNotification({ ...notification, show: false }),
  });

  useEffect(() => {
    getPlace();
  }, [placesData?._id]);

  function getPlace() {
    if (placesData) {
      formikRef?.current?.setFieldValue(
        "enabled",
        placesData.t2Settings?.enabled || false
      );
      return;
    }
  }

  const handleT2SettingsUpdate = (value) => {
    const type = "UPDATE";
    const t2Settings = {
      enabled: value.enabled
    }

    dispatch(updatePlace({ placeId, type, t2Settings }))
      .unwrap()
      .then((res) => {
        const success = res?.success;
        if (success) {
          dispatch(getPlaceById(placeId))
            .unwrap()
            .then((res) => {
              if (res?.success) {
                dispatch(setSelectedPlace(res?.place));
              }
            });
        }
        setNotification({
          ...notification,
          color: success ? "success" : "error",
          title: success ? "Success" : "Error",
          content: res?.message,
          icon: success ? "check" : "warning",
          show: true,
        });
      })
      .catch((err) => {
        console.error("Error creating:", err);
        setNotification({
          ...notification,
          color: "error",
          title: "Error",
          content: err?.message,
          icon: "warning",
          show: true,
        });
      });
  };

  return (
    <MDBox>
      <MDBox pt={2} pb={3}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            enabled: false,
          }}
          onSubmit={(value, action) => {
            console.log("values", value);
            handleT2SettingsUpdate(value);
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <MDBox pt={2} display="flex" justifyContent="start" alignItems="center">
                    <MDTypography variant="subtitle2" style={{minWidth: "120px"}}>
                      Enabled:
                    </MDTypography>
                    <Switch
                      checked={props.values.enabled}
                      onChange={(e) => {
                        props.handleChange(e);

                        props.setFieldValue(
                          "enabled",
                          e.target.checked
                        );
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </MDBox>
                </Grid>

                <Grid xs={12}>
                  <MDBox pt={2} display="flex" justifyContent="end">
                    <MDButton
                      color={sidenavColor}
                      variant="contained"
                      type="submit"
                    >
                      Save
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </MDBox>
      <MDSnackbar
        color={notification.color}
        icon={notification.icon}
        title={notification.title}
        content={notification.content}
        open={notification.show}
        close={notification.close}
        bgWhite
      />
    </MDBox>
  );
}

export default SpotHerongs;
