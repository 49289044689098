export const chatBotMessagePurpose = [
  {
    title: "Welcome Message",
  },
  {
    title: "Welcome Message (SMS)",
  },
  {
    title: "Extension Welcome",
  },
  {
    title: "Extension Link Expire",
  },
  {
    title: "Validation Link Expire",
  },

  {
    title: "Welcome Message (Validation)",
  },
  {
    title: "Welcome Message (Pay Now Validation Later Flow)",
  },
  {
    title: "Welcome Message (Parent Rate)",
  },
  {
    title: "After LicensePlate Submission SMS (Two Step Validation)",
  },
  {
    title: "After LicensePlate Submission (Two Step Validation)",
  },
  {
    title: "Payment Confirmation (Two Step Validation)",
  },
  {
    title: "Validation Reminder SMS",
  },
  {
    title: "On Rate Selection",
  },
  {
    title: "License Plate Form",
  },
  {
    title: "Monthly Rate Form",
  },
  {
    title: "Payment Card",
  },
  {
    title: "Payment Card (Monthly)",
  },

  {
    title: "Payment Confirmation (Pay Now Validation Later Flow)",
  },
  {
    title: "Payment Confirmation",
  },
  {
    title: "Payment Confirmation (Monthly)",
  },
  {
    title: "Payment Confirmation SMS",
  },
  {
    title: "Extension Reminder",
  },
  {
    title: "Unavailability",
  },
  {
    title: "Unavailability (Extension)",
  },
  {
    title: "Help",
  },
  {
    title: "Help SMS",
  },
  {
    title: "Learn More",
  },
  {
    title: "Contact",
  },
  {
    title: "Permit Creation Form",
  },
  {
    title: "Permit Form Submit",
  },
  {
    title: "Welcome Message (Permit)",
  },
];
export const SMSMessagePurpose = [
  {
    title: "Welcome Message",
  },
  {
    title: "On Hour Selection",
  },
  {
    title: "Help",
  },
  {
    title: "Learn More",
  },
  {
    title: "Contact",
  },
];

export const purposeTags = {
  "After LicensePlate Submission (Two Step Validation)": [
    "displayName",
    "placeAddress",
    "brandName",
    "lotName",
  ],
  "After LicensePlate Submission SMS (Two Step Validation)": [
    "placeAddress",
    "brandName",
    "lotName",
    "displayName",
    "validationLink",
  ],
  Contact: ["placeAddress", "brandName", "lotName"],
  "Expiration Reminder": [
    "placeAddress",
    "brandName",
    "lotName",
    "extensionLink",
  ],
  "Extension Reminder": ["placeAddress", "brandName", "lotName"],
  "Extension Welcome": ["placeAddress", "brandName", "lotName"],
  Help: ["placeAddress", "brandName", "lotName"],
  "Learn More": ["placeAddress", "brandName", "lotName"],
  "License Plate Form": ["placeAddress", "brandName", "lotName"],
  "On Rate Selection": ["placeAddress", "brandName", "lotName", "totalAmount"],
  "Payment Card": ["totalAmount", "placeAddress", "brandName", "lotName"],
  "Payment Confirmation": ["placeAddress", "brandName", "lotName"],
  "Payment Confirmation (Monthly)": ["placeAddress", "brandName", "lotName"],
  "Payment Confirmation SMS": [
    "placeAddress",
    "brandName",
    "lotName",
    "licensePlate",
    "totalAmount",
    "startDate",
    "startTime",
    "endDate",
    "endTime",
    "receiptURL",
  ],
  Unavailability: ["placeAddress", "brandName", "lotName"],
  "Validation Reminder SMS": ["placeAddress", "brandName", "lotName"],
  "Validation Welcome Message": ["placeAddress", "brandName", "lotName"],
  "Welcome Message": ["placeAddress", "brandName", "lotName"],
  "Welcome Message (Parent Rate)": [
    "brandName",
    "placeAddress",
    "lotName",
    "displayName",
  ],
  "Welcome Message (Validation)": ["brandName", "placeAddress", "lotName"],
  "Welcome Message (Permit)": ["placeAddress", "brandName", "lotName"],
  "": [],
};

export const chatBotMessagePurposeColorCodes = {
  "Welcome Message": "#FF5733",
  "Extension Welcome": "#00FF00",
  "Welcome Message (Validation)": "#0000FF",
  "Welcome Message (Parent Rate)": "#FF00FF",
  "After LicensePlate Submission SMS (Two Step Validation)": "#FF0066",
  "After LicensePlate Submission (Two Step Validation)": "#8000FF",
  "Validation Reminder SMS": "#00FF99",
  "On Rate Selection": "#FF8000",
  "License Plate Form": "#80FF00",
  "Monthly Rate Form": "#3300FF",
  "Payment Card": "#00FF33",
  "Payment Card (Monthly)": "#FF00CC",
  "Payment Confirmation": "#3399FF",
  "Payment Confirmation (Monthly)": "#FF3399",
  "Payment Confirmation SMS": "#00FF80",
  "Extension Reminder": "#CCFF00",
  Unavailability: "#FF0000",
  Help: "#00FF66",
  "Learn More": "#0000CC",
  Contact: "#FF00FF",
  "Permit Creation Form": "#FF0066",
  "Permit Form Submit": "#8000FF",
  "Welcome Message (Permit)": "#06402B",
};
